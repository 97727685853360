<template>
  <div>
    <div class="position-relative body-container" style="padding-top: 0px; padding-bottom: 0px;">
      <div>
        <img
          src="/img/logo_selcare_512.png"
          class="mobile-icon"
          alt=""
          loading="lazy"
        />
      </div>
      <div class="banner-home d-none d-lg-block">
        <div class="row">
          <div class="col-md-6">
            <div class="banner-title">
              <h1>How Can We Help You Today</h1>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <div class="banner-description-box">
              <p v-if="
                this.currentCustomer &&
                this.currentCustomer.user &&
                this.currentCustomer.user.username
              " class="mb-1 ff-desc semi-title">
                Hello,
                <span style="font-weight: 600">{{
                  `${ currentCustomer.user.firstname? currentCustomer.user.firstname : currentCustomer.user.username }`
                }}</span
                >!
              </p>
              <p class="">
                Discover convenience, quality, and personalized care with Selcare.com, your ultimate online healthcare 
                services platform. From nursing care to hassle-free pharmacy purchases, we have it all. Consult with 
                healthcare professionals through telemedicine, enjoy e-prescriptions, and book dental and clinic appointments 
                with ease. <br /><br />
                Explore Selcare.com today and embrace the future of healthcare.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="w-100 mt-5">
        <div
          class="text-left mx-2"
          v-if="
            this.currentCustomer &&
            this.currentCustomer.user &&
            this.currentCustomer.user.username
          "
        >
          <p class="mb-1 ff-desc semi-title">
            Hello,
            <span style="font-weight: 600">{{
              `${ currentCustomer.user.firstname? currentCustomer.user.firstname : currentCustomer.user.username }`
            }}</span
            >!
          </p>
          <h2 class="text-left mb-0 ff-title fw-strong">
            How can we help you today?
          </h2>
        </div>
        <h2 class="text-left mx-2 mb-0 ff-title fw-strong" v-else>
          Hello, how can we help you today?
        </h2>
      </div> -->
      <div class="mt-4 adam@gmail.com d-flex container-card w-100">
        <div
          class="m-2 card-item-container"
          v-if="
            !this.currentCustomer ||
            (this.currentCustomer &&
              this.currentCustomer.user.role.name == 'Customer')
          "
        >
          <router-link to="/home-nursing/booking">
            <div
              id="card-1"
              class="
                shadow-sm
                text-white
                position-relative
                rounded-cutom
                overflow-hidden
                card-item
                ff-title
              "
            >
              <div class="bg-nursing"></div>
              <span class="position-absolute card-title"
                >Home Nursing / <br />
                Physiotherapy</span
              >
              <!-- <img
                src="/img/card_1.png"
                style="height: 100%; width: auto"
                alt=""
              /> -->
            </div>
          </router-link>
        </div>
        <div
          class="m-2 card-item-container"
          v-if="
            !this.currentCustomer ||
            (this.currentCustomer &&
              this.currentCustomer.user.role.name == 'Customer')
          "
        >
        <a href="#" >
            <div
              id="card-2"
              class="
                shadow-sm
                bg-pharmacy
                text-white
                position-relative
                rounded-cutom
                overflow-hidden
                card-item
                ff-title
              "
            >
              <div class="bg-pharmacy"></div>
              <span class="position-absolute card-title"
                >Pharmacy & <br />
                Medical Supplies</span
              >
            </div>
          </a>
          <!-- <a v-if="!currentCustomer" href="https://jovianselcare.com/" target="_blank">
            <div
              id="card-2"
              class="
                shadow-sm
                bg-pharmacy
                text-white
                position-relative
                rounded-cutom
                overflow-hidden
                card-item
                ff-title
              "
            >
              <div class="bg-pharmacy"></div>
              <span class="position-absolute card-title"
                >Pharmacy & <br />
                Medical Supplies</span
              >
            </div>
          </a>
          <router-link v-else to="/customer/jovianselcare-generate-token">
            <div
              id="card-2"
              class="
                shadow-sm
                bg-pharmacy
                text-white
                position-relative
                rounded-cutom
                overflow-hidden
                card-item
                ff-title
              "
            >
              <div class="bg-pharmacy"></div>
              <span class="position-absolute card-title"
                >Pharmacy & <br />
                Medical Supplies</span
              >
            </div>
          </router-link> -->
        </div>
        <div
          class="m-2 card-item-container"
          v-if="
            !this.currentCustomer ||
            (this.currentCustomer &&
              this.currentCustomer.user.role.name == 'Customer')
          "
        >
          <!-- <router-link v-if="this.currentCustomer == null" to="/auth/login">
            <div
              id="card-3"
              class="
                shadow-sm
                text-white
                position-relative
                rounded-cutom
                overflow-hidden
                card-item
                ff-title
              "
            >
              <div class="bg-telemedicine"></div>
              <span class="position-absolute card-title"
                >Telemedicine / <br />
                E-prescription</span
              >
            </div>
          </router-link> -->
          <router-link
            class="card-item-container"
            to="/e-prescription/user-profile"
          >
            <div
              id="card-3"
              class="
                shadow-sm
                text-white
                position-relative
                rounded-cutom
                overflow-hidden
                card-item
                ff-title
              "
            >
              <div class="bg-telemedicine"></div>
              <span class="position-absolute card-title"
                >Telemedicine / <br />
                E-prescription</span
              >
            </div>
          </router-link>
          <!-- <div
            id="card-3"
            class="
              shadow-sm
              text-white
              position-relative
              rounded-cutom
              overflow-hidden
              card-item
              ff-title
            "
          >
            <div class="bg-telemedicine"></div>
            <span class="position-absolute card-title"
              >Tele Medicine / <br />
              E-Prescription</span
            >
            <div class="coming-soon-card">
              <span>Coming Soon</span>
            </div>
          </div> -->
        </div>
        <div
          class="m-2 card-item-container"
          v-if="
            !this.currentCustomer ||
            (this.currentCustomer &&
              this.currentCustomer.user.role.name == 'Customer')
          "
        >
          <!-- <router-link v-if="this.currentCustomer == null" to="/auth/login">
            <div
              id="card-4"
              class="
                shadow-sm
                text-white
                position-relative
                rounded-cutom
                overflow-hidden
                card-item
                ff-title
              "
            >
              <div class="bg-dental"></div>
              <span class="position-absolute card-title"
                >Clinic/Dental <br />
                Appointment</span
              >
            </div>
          </router-link>
          <router-link
            class="card-item-container"
            v-else-if="
              this.currentCustomer &&
              this.currentCustomer.user.role.name == 'Customer'
            "
            to="/clinic-appointment/manage"
          >
            <div
              id="card-4"
              class="
                shadow-sm
                text-white
                position-relative
                rounded-cutom
                overflow-hidden
                card-item
                ff-title
              "
            >
              <div class="bg-dental"></div>
              <span class="position-absolute card-title"
                >Clinic/Dental <br />
                Appointment</span
              >
            </div>
          </router-link> -->
          <router-link
            class="card-item-container"
            to="/clinic-appointment/manage"
          >
            <div
              id="card-4"
              class="
                shadow-sm
                text-white
                position-relative
                rounded-cutom
                overflow-hidden
                card-item
                ff-title
              "
            >
              <div class="bg-dental"></div>
              <span class="position-absolute card-title"
                >Clinic/Dental <br />
                Appointment</span
              >
            </div>
          </router-link>
          <!-- <div
            id="card-4"
            class="
              shadow-sm
              text-white
              position-relative
              rounded-cutom
              overflow-hidden
              card-item
              ff-title
            "
          >
            <div class="bg-dental"></div>
            <span class="position-absolute card-title"
              >Clinic/Dental <br />
              Appointment</span
            >
            <div class="coming-soon-card">
              <span>Coming Soon</span>
            </div>
          </div> -->
        </div>
        <div
          class="m-2 card-item-container"
          v-if="
            !this.currentCustomer ||
            (this.currentCustomer &&
              this.currentCustomer.user.role.name == 'Customer')
          "
        >
          <a href="https://secure.selcare.my" target="_blank">
            <div
              id="card-1"
              class="
                shadow-sm
                text-white
                position-relative
                rounded-cutom
                overflow-hidden
                card-item
                ff-title
              "
            >
              <div class="bg-management"></div>
              <span class="position-absolute card-title"
                >Selcare Corporate / <br />
                TPA</span
              >
              <!-- <img
                src="/img/card_1.png"
                style="height: 100%; width: auto"
                alt=""
              /> -->
            </div>
          </a>
        </div>
      </div>
      <!-- <div class="w-100">
        <p class="text-muted mx-2 text-break ff-desc">
          Selgate Healthcare is a member of Selgate Group of Companies which
          focuses on providing the best healthcare solution for the people. We
          are confident of serving the clients better through a network of
          general practice clinics, dental clinics, pharmacy and home nursing
          setup manned by well-trained doctors, dentists, nurses and
          pharmacists.Call us at
          <a
            class="font-weight-bold"
            style="color: #a91a18"
            href="tel:1800226600"
            >1 800 22 6600</a
          >
          or email us at
          <a
            class="font-weight-bold"
            style="color: #a91a18"
            href="mailto:info@selgatehealthcare.com"
            >admin.g@selgatecorporation.com</a
          >. We are always there to care.
        </p>
        <button
          type="button"
          class="btn btn-link btn-sm mt-4 w-100 text-center mb-4"
          @click="goToPolicyPage()"
        >
          <i class="fas fa-shield-alt mr-2"></i>Privacy & Policy
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
// import MobileDataClass from './../../utils/classes/MobileDataClass'
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      // currentView : 'home'
    };
  },
  computed: {
    ...mapGetters(["currentCustomer", "consultation"]),
  },
  methods: {
    goToPolicyPage() {
      this.$router.push({
        path: "/info/policy",
      });
    },
    // callJSCurrentView(){
    //     return this.currentView
    // }
  },
  mounted() {
    //android/ios sendata function callback
    // let Android = new MobileDataClass()
    // Android.sendData('home')
    // window.callJSCurrentView = this.callJSCurrentView
    // console.log('current view : ', window.callJSCurrentView())

    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.sendData
    ) {
      window.webkit.messageHandlers.sendData.postMessage("home");
    }
    try {
      if ($Android) {
        $Android.sendData("home");
      }
    } catch (err) {
      // console.log(".. .");
    }
  },
};
</script>

<style scoped>
.container {
  position: relative;
  height: calc(100vh - 165px);
}

.body-container {
  /* height: calc(100vh - 131px); */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container-card {
  flex-direction: row !important;
}

.card-item {
  transition: ease-in-out 1000ms;
  height: 160px;
  width: 177px;
}

.card-item > div {
  transition: ease-in-out 1000ms;
  height: 100%;
  width: 100%;
}

.card-item:hover > div {
  transition: ease-in-out 1000ms;
  transform: scale(1.2);
}

.card-item:hover > .card-title {
  transition: ease-in-out 1000ms;
  background-color: #ffffff;
  /* bottom: -100px; */
  /* opacity: 0; */
}

.mobile-icon {
  display: none;
}

.card-title {
  display: flex;
  align-items: flex-end;
  /* height: 100%; */
  /* text-shadow: 0px 1px 4px #000000; */
  width: 100%;
  background-color: #ffffff9c;
  color: #212529;
  font-weight: bold;
  font-family: "Karla", serif;
  padding: 1rem;
  font-size: 0.9rem;
  border-right: 5px solid #a71b17;
}

.coming-soon-card {
  position: absolute;
  bottom: 0px;
  background: #ffffffb8;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.coming-soon-card span {
  color: #000;
  width: 100%;
  display: flex;
  font-weight: 700;
  justify-content: center;
  padding-bottom: 60px;
}

/* Home banner section  */
.banner-home {
  width: 100%;
  padding: 30px 30px;
  /* background-image: url('/img/homepage_banner.jpg'); */
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url('/img/homepage_banner_4.jpg');
  /* background-size: 1400px; */
  background-size: 100%;
  background-repeat: no-repeat;
}
.banner-home .banner-title {
  max-width: 400px;
}
.banner-home .banner-title::after {
  content: '';
  border-bottom: 5px solid #9a9a9a;
  width: 45%;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
}
.banner-home .banner-title h1 {
  color: #a71b17;
  font-weight: bold;
  text-transform: uppercase;
}
.banner-home .banner-description-box {
  max-width: 400px;
  background: #a71b17;
  padding: 25px 30px;
  border-radius: 10px;
}
.banner-home .banner-description-box p {
  color: #fff;
}

/* Home banner section  */

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .card-title {
    bottom: 0px;
    left: 0px;
    /* bottom: 1rem;
    left: 1rem; */
    margin: 0px;
  }
  .mobile-icon {
    display: block;
    height: 100px;
    margin-bottom: 3rem;
  }
  .body-container {
    height: 100%;
    margin: 30px;
  }
  .card-item {
    height: 250px;
    width: 100%;
    margin-bottom: 20px;
  }
  .container-card {
    width: 100%;
    flex-direction: column !important;
  }

  .card-item img {
    float: right;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .card-title {
    bottom: 0px;
    left: 0px;
    /* bottom: 1rem;
    left: 1rem; */
    margin: 0px;
  }
  .mobile-icon {
    display: block;
    height: 100px;
    margin-bottom: 3rem;
  }
  .body-container {
    height: 100%;
    margin: 30px;
  }
  .card-item {
    height: 250px;
    width: 100%;
    margin-bottom: 20px;
  }
  .container-card {
    width: 100%;
    flex-direction: column !important;
  }

  .card-item img {
    float: right;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .card-title {
    bottom: 0px;
    left: 0px;
    /* bottom: 1rem;
    left: 1rem; */
    margin: 0px;
  }
  .mobile-icon {
    display: block;
    height: 100px;
    margin-bottom: 3rem;
  }
  .body-container {
    height: 100%;
    margin: 30px;
  }
  .card-item {
    height: 250px;
    width: 100%;
    margin-bottom: 20px;
  }
  .container-card {
    width: 100%;
    flex-direction: column !important;
  }

  .card-item img {
    float: right;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-title {
    bottom: 0px;
    left: 0px;
    /* bottom: 0.5rem;
    left: 0.5rem; */
    margin: 0px;
  }
  .body-container {
    /* max-width: 880px; */
    margin: auto;
    padding: 50px;
  }

  .card-item-container {
    width: 20%;
  }

  .card-item {
    height: 160px;
    width: 100%;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .card-title {
    bottom: 0px;
    left: 0px;
    /* bottom: 0.5rem;
    left: 0.5rem; */
    margin: 0px;
  }
  .body-container {
    /* max-width: 970px;
    margin: auto; */
    margin: auto;
    padding: 50px;
  }

  .card-item-container {
    width: 20%;
  }

  .card-item {
    height: 220px;
    min-width: 100%;
  }
}

.sub-container {
  padding: 0px 150px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}
.main-title {
  text-align: center;
  margin-bottom: 30px;
}

.maintenance-overlay-label {
  position: absolute;
  z-index: 1;
  top: 45%;
}
.maintenance-overlay-label span {
  font-size: 10px;
  color: #fff;
  display: block;
  background-color: #a81a17;
  width: 179px;
  text-align: center;
  padding: 5px 0px;
}

.card {
  height: 160px;
  border-radius: 15px;
  border: unset;
  position: relative;
  overflow: hidden;
}
.card span.name {
  color: #ffffff;
  font-weight: 500;
  position: absolute;
  bottom: 15px;
  left: 12px;
}
.card img {
  position: absolute;
  right: 0px;
  height: 100%;
}
.card.no-exist span.name,
.card.no-exist img {
  opacity: 0.4;
}
.card.no-exist button.coming-soon {
  background-color: rgba(144, 152, 161, 0.7);
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  width: 100px;
  border-radius: 20px;
  position: absolute;
  top: 65px;
  left: 45px;
  opacity: 1 !important;
}

#card-1 {
  background-color: #e14db8;
}
#card-2 {
  background-color: #5e38ba;
}
#card-3 {
  background: linear-gradient(138.52deg, #e85d27 4.81%, #ff2e31 100.76%);
}
#card-4 {
  background: linear-gradient(
    138.52deg,
    rgba(39, 104, 232, 0.67) 4.81%,
    rgba(46, 192, 255, 0.67) 100.76%
  );
}

@media (max-width: 1199px) {
  .sub-container {
    padding: 0px 80px;
  }

  .maintenance-overlay-label span {
    width: 169px;
  }
}
@media (max-width: 991px) {
  .sub-container {
    padding: 0px 0px;
  }
  .card img {
    position: absolute;
    right: 0px;
    height: 80%;
    bottom: 0px;
  }

  .maintenance-overlay-label span {
    width: 149px;
  }
}
@media (max-width: 767px) {
  .maintenance-overlay-label span {
    width: 509px;
  }
}
@media (max-width: 575px) {
  .sub-container {
    padding: 0px 10px;
    /* margin-top: 30px; */
  }
  .container {
    height: calc(100vh - 140px);
  }
  .home-logo {
    height: 100px;
  }
  .main-title h2 {
    font-family: "Karla", serif;
    font-size: 1.2rem;
  }
  .card img {
    position: absolute;
    right: 0px;
    height: 80%;
    bottom: 0px;
  }

  .maintenance-overlay-label {
    width: calc(100% - 23px);
  }
  .maintenance-overlay-label span {
    width: 100%;
  }

  p.description-text {
    font-size: 0.9rem;
    text-align: justify;
    margin-top: -15px;
    color: #545d69;
  }
  p.description-text .text-muted {
    font-family: "Karla", serif;
    color: #2b2b2b !important;
  }
}
@media (max-height: 700px) {
  .container {
    height: 100vh;
  }
}

/** Others */
.semi-title {
  font-size: 21px;
}
.mt-2-custom {
  margin-top: 15px;
}

.rounded-cutom {
  border-radius: 0.8rem !important;
}

.bg-nursing {
  background-image: url("../../assets/nursing2.jpg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.bg-telemedicine {
  background-image: url("../../assets/telemedicine-2.jpg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.bg-dental {
  background-image: url("/img/clinic_dental.jpg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.bg-pharmacy {
  background-image: url("../../assets/pharmacy.jpg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.bg-management {
  background-image: url("../../assets/management.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
</style>